import * as React from "react"

import Layout from "../../components/layout/layout"
import PluginsHeroSectionNew from "../../components/sections/plugins-hero-section-new"
import PluginsPageSquarespaceListSectionNew from "../../components/sections/plugins-page-squarespace-list-section-new"
import Seo from "../../components/seo"


const PluginsSquarespacePage = () => {
  return (
    <Layout>
      <Seo title="Best Squarespace Plugins" />

      <PluginsHeroSectionNew subTitle="Best Squarespace Plugins" />

      <PluginsPageSquarespaceListSectionNew />
    </Layout>
  )
}

export default PluginsSquarespacePage
