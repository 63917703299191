import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PluginsPageSquarespaceListSectionNew = () => {
  const data = useStaticQuery(graphql`
    query PluginsPageSquarespaceListSectionNewQuery {
      allPrismicPluginApp(
        filter: {data: {platform: {eq: "Squarespace"}}},
        sort: {fields: data___date_published, order: DESC},
        limit: 21
      ) {
        edges {
          node {
            data {
              date_published
              plugin_app_name
              plugin_app_link {
                url
              }
              platform
              price
              plugin_app_image {
                alt
                gatsbyImageData(
                  width: 400
                  height: 250
                )
              }
            }
          }
        }
      }
    }
  `)

  const doc = data.allPrismicPluginApp.edges

  return (
    <div className="bg-gray-100 w-full px-4 pt-0 pb-0 md:pb-24">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xl:gap-12">
          {doc.map(item => {
            const pluginAppImg = getImage(item.node.data.plugin_app_image)

            return (
              <div className="w-full mb-12 md:mb-0">
                <a href={item.node.data.plugin_app_link.url} target='_blank' rel='noopener noreferrer'>
                  <GatsbyImage
                    image={pluginAppImg}
                    alt={pluginAppImg.alt}
                    className="border border-gray-300 hover:border-gray-900"
                  />
                </a>

                <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-6"><a href={item.node.data.plugin_app_link.url} target='_blank' rel='noopener noreferrer'>{item.node.data.plugin_app_name}</a></h2>

                <p className="text-gray-600 text-md font-medium uppercase mt-2">{item.node.data.price}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PluginsPageSquarespaceListSectionNew